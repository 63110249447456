import {
  find,
  findAll,
  next,
  show,
  closest,
  hasClass,
  addClass,
  isChecked,
  removeClass,
  toggleClass,
  documentReady,
  attachDynamicEvent,
} from './../../common/helpers/dom'

documentReady(function (e) {
  if (!find('.new-payment-management')) {
    return
  }

  // Opening the dropdown
  attachDynamicEvent('.payment-method-dropdown', 'click', function (e) {
    e.stopPropagation()
    let select = closest(this, (el) => hasClass(el, 'payment-method-panel'))
    select = select && find('.payment-method-select', select)
    select && toggleClass(select, 'hide')
  })

  // Clicking anywhere but the dropdown hides the dropdown
  document.body.addEventListener('click', function (e) {
    if (!e.target.closest('.payment-method-dropdown')) {
      addClass(
        find('#saved_payment_method_select .payment-method-select'),
        'hide'
      )
    }
  })

  attachDynamicEvent('.add-new-payment', 'click', function (e) {
    show(find('#payment_processor_select_panel'))
    removeClass(find('#payment_processor_select'), 'hide')
    let existingPaymentMethodSelectElements = findAll(
      '#saved_payment_method_select .payment-dropdown-container, #saved_payment_method_select .payment-method-select'
    )
    existingPaymentMethodSelectElements.forEach((el) =>
      el.parentNode.removeChild(el)
    )
    find('input[name="hide_existing_payment_methods"]').value = '1'
    find('#payment_processor_select_panel input[type=radio]:checked').click() // show card fields if required
    e.preventDefault()
  })

  attachDynamicEvent(
    '#saved_payment_method_select .payment-method-select input[type="radio"][name="purchase[payment_method_id]"]',
    'click',
    function (e) {
      if (!isChecked(this)) {
        return
      }

      let selected = find(
        'span:first-child',
        next(this, (el) => hasClass(el, 'label_text'))
      ).innerHTML
      let target = closest(this, (el) => hasClass(el, 'payment-method-panel'))
      target =
        target &&
        find(
          '.payment-dropdown-container .payment-method-dropdown span:first-child',
          target
        )
      target.innerHTML = selected
    }
  )
})
