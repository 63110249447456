import { find, addClass, documentReady } from './../../common/helpers/dom'

documentReady(function () {
  const paymentForm = find('.payment-form')
  const subscriptionTableExists = find('.table-info-list')
  const statsBarExists = find('.stats-bar-padded')
  if (
    paymentForm &&
    ![subscriptionTableExists, statsBarExists].some((v) => v)
  ) {
    addClass(paymentForm, 'first-item')
  }
})
